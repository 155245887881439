import React from "react";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { useIntl } from "gatsby-plugin-react-intl";
import { truncate as _truncate } from 'lodash';

const Reviews = ({ reviews }) => {
  const intl = useIntl();

  if (reviews === undefined) {
    return '';
  }


  return (
    <div className="ticket-pricing-details">
      <h3 className="bold">
        {intl.formatMessage({
          id: 'google_reviews',
        })}
      </h3>
      <Splide
        className="review-slider"
        options={{
          rewind: true,
          perPage: 1,
          perMove: 1,
          gap: '1.7rem',
          pagination: false,
          arrows: 'slider',
          lazyLoad: 'nearby',
          autoplay: true,
          type: 'loop',
          pauseOnHover: true,
          autoHeight: true
        }}
      >

        {reviews?.map((review) => {
          if (review.rating < 3 || review.author_name === 'Philip Nordt') {
            return '';
          }

          const ratingsElements = [];
          for (let i = 1; i <= 5; i++) {
            ratingsElements.push(
              <><input type="radio" id={`${i}-stars`} name="rating" value={i} />
                <label for={`${i}-stars`} class={`star ${i <= review.rating ? 'active' : ''}`}>&#9733;</label></>);
          }
          return (
            <SplideSlide key={review.time}>
              <div className="card review-card">
                <div className="card-content">
                  <div className="media is-align-items-center	">
                    <div className="media-left">
                      <figure className="image is-48x48">
                        <img src={review.profile_photo_url} alt={review.author_name} />
                      </figure>
                    </div>
                    <div className="media-content">
                      <p className="title is-5">{review.author_name}</p>
                    </div>
                  </div>

                  <div className="content">
                    {_truncate(review.text, { length: 150, omission: '...', })}
                    <br />
                    <time className="time is-size-7" datetime="2016-1-1">{review.relative_time_description}</time>
                    <div class="star-rating">
                      {ratingsElements}
                    </div>
                  </div>
                </div>
              </div>
            </SplideSlide>
          );
        })}
      </Splide>
    </div >
  );
};

export default Reviews;