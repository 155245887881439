import React, { useEffect, useState, useRef } from 'react';
import CalendarIcon from '../assets/images/calendar.svg';
import 'react-datepicker/dist/react-datepicker.css';
import { useIntl, navigate } from 'gatsby-plugin-react-intl';
import DatePicker from 'react-datepicker';
import Layout from '../layouts/Layout';
import Seo from '../components/Seo/Seo';
import queryString from 'query-string';
import dayjs from 'dayjs';
import API from '../api';
import { optimizeImageUrl } from "../utils/cloudinary";
import { AdvancedImage, responsive, lazyload } from '@cloudinary/react';

import { size as _size, get as _get } from 'lodash';
import Slider from './components/Slider';
import ReadMore from '../components/ReadMore';
import { removeTags } from '../utils/utils';
import Reviews from '../components/Reviews';
import GoogleMap from '../components/GoogleMap/GoogleMap';

const isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

export default function Event({ pageContext: { event, reviews } }) {
  const [venue, setVenue] = useState({});
  const [date, setDate] = useState('');
  const [availableDates, setAvailableDates] = useState([]);
  const [selectedNrOfTickets, setSelectedNrOfTickets] = useState({});
  const [bookUrl, setBookUrl] = useState('');
  const [images, setImages] = useState([]);
  const [prefilledDate, setPrefilledDate] = useState('');
  const ticketInfoRef = useRef(null);
  const fixedNavRef = useRef(null);
  const bookTicketInfoRef = useRef(null);

  const intl = useIntl();

  const buildUrl = (_date) => {
    let url = `payment?eventId=${event.id}`;
    if (_date) {
      url += `&eventDate=${dayjs(_date).format('DD/MM/YYYY')}`;
    }

    if (_size(selectedNrOfTickets) > 0) {
      let ticketIds = [];
      for (const [key, value] of Object.entries(selectedNrOfTickets)) {
        ticketIds.push(`${key}:${value}`);
      }

      url += `&ticketIds=[${ticketIds}]`;
    }
    setBookUrl(url);
  };

  useEffect(() => {
    getEventVenue(event);
    organizeAvailableDates(event.event_dates);
    setImages(event?.images?.split(','));
  }, [event]);

  useEffect(() => {
    buildUrl(prefilledDate);
  }, [date, prefilledDate, selectedNrOfTickets]);

  useEffect(() => {
    const urlParams = queryString.parse(window.location.search);
    if (urlParams?.date) {
      setPrefilledDate(urlParams?.date);
    }
  }, []);

  const handleFixedNavigation = () => {
    const offset = window.scrollY;
    let navHeight = 0;

    if (fixedNavRef?.current && bookTicketInfoRef.current) {
      const { offsetTop, offsetHeight } = bookTicketInfoRef.current;
      navHeight = offsetTop + offsetHeight;

      if (offset > (navHeight + 300) && fixedNavRef.current.style.visibility !== 'flex') {
        fixedNavRef.current.style.display = 'flex';
      } else if (offset <= navHeight && fixedNavRef.current.style.visibility !== 'none') {
        fixedNavRef.current.style.display = 'none';
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleFixedNavigation);

    return () => {
      window.removeEventListener('scroll', () => handleFixedNavigation);
    };
  }, []);

  const getEventVenue = (event) => {
    API.get(`venues/${event.venue_id}`)
      .then((res) => {
        setVenue(res.data);
      })
      .catch((err) => console.error(err));
  };

  const organizeAvailableDates = (eventDates) => {
    const available_dt = [];
    eventDates.map((event_date) =>
      available_dt.push(new Date(event_date.datetime))
    );
    setAvailableDates(available_dt);
  };

  const onChangeSelectedNumberOfTicket = (e, eventTicketId, state) => {
    if (state === 'increase') {
      setSelectedNrOfTickets((prevValue) => ({
        ...prevValue,
        [eventTicketId]:
          typeof prevValue[eventTicketId] !== 'undefined'
            ? prevValue[eventTicketId] + 1
            : 1,
      }));
    } else {
      setSelectedNrOfTickets((prevValue) => ({
        ...prevValue,
        [eventTicketId]:
          typeof prevValue[eventTicketId] !== 'undefined' && prevValue[eventTicketId] > 0
            ? prevValue[eventTicketId] - 1
            : 0,
      }));
    }
  };

  const validateAndRedirectToPayment = (ev) => {
    ev.preventDefault();
    localStorage.setItem('shopping_cart', `/${bookUrl}`);
    navigate(`/${bookUrl}`);
  };

  const goToBookingButton = () => {
    ticketInfoRef.current.scrollIntoView();
  };

  const onChangeDate = (date) => {
    setDate(date);
    setPrefilledDate(date);
  };



  const isOnDateRangeAndReturnThePrice = (date, ticketsCategoryCustomPrices) => {
    const isOnDateRange = ticketsCategoryCustomPrices.filter((item) => {
      const startDate = dayjs(item.from);
      const endDate = dayjs(item.to);
      return dayjs(date).isBetween(startDate, endDate, 'day', '[]');
    });

    return {
      status: isOnDateRange.length > 0,
      price: isOnDateRange.length > 0 ? isOnDateRange[0].price : null
    };
  };


  const isDeLang = () => intl.locale === 'de';

  const eventName = isDeLang() && event.name_de !== null ? event.name_de : event.name;

  return (
    <Layout section="detail" backgroundImage={event?.main_image}>
      <div className="fixed-nav" ref={fixedNavRef}>
        <span
          className="nav-button"
          onClick={goToBookingButton}
        >
          {intl.formatMessage({ id: 'book_ticket' })}
        </span>
      </div>
      <section className="section event-detail-content">
        <div className="container mobile-container">
          <h2 className="bold">{eventName}</h2>
          <div className="columns event-details">
            <div className="column is-8">
              <div className="event-detail-wrapper">
                <h3 className="bold">
                  {intl.formatMessage({
                    id: 'details',
                    defaultMessage: 'Detail',
                  })}
                </h3>
                <div className="columns">
                  <div className="column px-0-mobile is-12">
                    <div className="columns event-info-row is-mobile is-align-items-center">
                      <div className="column pl-0-mobile is-6-desktop is-half-mobile">
                        <p>
                          {intl.formatMessage({
                            id: 'venue',
                          })}
                          :
                        </p>
                      </div>
                      <div className="column pl-0-mobile flex-end-mobile is-6-desktop is-half-mobile">
                        <span>{venue.name}</span>
                      </div>
                    </div>
                    {event.group === 'concert_with_dinner' ? (
                      <>
                        <div className="columns event-info-row is-mobile is-align-items-center">
                          <div className="column pl-0-mobile is-6-desktop is-half-mobile">
                            <p>
                              {intl.formatMessage({
                                id: 'address_restaurant',
                              })}
                              :
                            </p>
                          </div>
                          <div className="column pl-0-mobile flex-end-mobile is-6-desktop is-half-mobile">
                            <span>{venue?.restaurant?.location ?? '/'}</span>
                          </div>
                        </div>
                        <div className="columns event-info-row is-mobile is-align-items-center">
                          <div className="column pl-0-mobile is-6-desktop is-half-mobile">
                            <p>
                              {intl.formatMessage({
                                id: 'time_to_be_there',
                              })}
                              :
                            </p>
                          </div>
                          <div className="column pl-0-mobile flex-end-mobile is-6-desktop is-half-mobile">
                            <span> {dayjs('01/01/01 ' + event.restaurant_admission_time).format('HH:mm')}</span>
                          </div>
                        </div>
                        <div className="columns event-info-row is-mobile is-align-items-center">
                          <div className="column pl-0-mobile is-6-desktop is-half-mobile">
                            <p>
                              {intl.formatMessage({
                                id: 'address_concert',
                              })}
                              :
                            </p>
                          </div>
                          <div className="column pl-0-mobile flex-end-mobile is-6-desktop is-half-mobile">
                            <span>{venue.location}</span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="columns event-info-row is-mobile is-align-items-center">
                        <div className="column pl-0-mobile is-6-desktop is-half-mobile">
                          <p>
                            {intl.formatMessage({
                              id: 'address',
                            })}
                            :
                          </p>
                        </div>
                        <div className="column pl-0-mobile flex-end-mobile is-6-desktop is-half-mobile">
                          <span>{venue.location}</span>
                        </div>
                      </div>
                    )}
                    <div className="columns event-info-row is-mobile is-align-items-center">
                      <div className="column pl-0-mobile is-6-desktop is-half-mobile">
                        <p>
                          {intl.formatMessage({
                            id: 'admission',
                          })}
                          :
                        </p>
                      </div>
                      <div className="column pl-0-mobile flex-end-mobile is-6-desktop is-half-mobile">
                        <span>
                          {dayjs('01/01/01 ' + event.start_time)
                            .subtract(30, 'minutes')
                            .format('HH:mm')}
                        </span>
                      </div>
                    </div>
                    <div className="columns event-info-row is-mobile is-align-items-center">
                      <div className="column pl-0-mobile is-6-desktop is-half-mobile">
                        <p>
                          {intl.formatMessage({
                            id: 'begin',
                          })}
                          :
                        </p>
                      </div>
                      <div className="column pl-0-mobile flex-end-mobile is-6-desktop is-half-mobile">
                        <span>
                          {dayjs('01/01/01 ' + event.start_time).format(
                            'HH:mm'
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="columns event-info-row is-mobile is-align-items-center">
                      <div className="column pl-0-mobile is-6-desktop is-half-mobile">
                        <p>
                          {intl.formatMessage({
                            id: 'end',
                          })}
                          :
                        </p>
                      </div>
                      <div className="column pl-0-mobile flex-end-mobile is-6-desktop is-half-mobile">
                        <span>
                          {dayjs('01/01/01 ' + event.end_time).format('HH:mm')}
                        </span>
                      </div>
                    </div>
                    {/* <div className="columns event-info-row is-mobile">
                      <div className="column is-6-desktop is-half-mobile">
                        <p>
                          {intl.formatMessage({
                            id: 'frequency',
                          })}
                          :
                        </p>
                      </div>
                      <div className="column is-6">
                        <span>
                          {intl.formatMessage({
                            id: `frequency_${event.frequency}`,
                          })}
                        </span>
                      </div>
                    </div> */}
                    <div className="columns event-info-row is-mobile is-align-items-center">
                      <div className="column pl-0-mobile is-6-desktop is-half-mobile">
                        <p>
                          {intl.formatMessage({
                            id: 'free_seat_assignment',
                          })}
                          :
                        </p>
                      </div>
                      <div className="column pl-0-mobile flex-end-mobile is-6-desktop is-half-mobile">
                        <span>
                          {event.free_seat_assignment === 1
                            ? intl.formatMessage({
                              id: 'yes',
                            })
                            : intl.formatMessage({
                              id: 'no',
                            })}
                        </span>
                      </div>
                    </div>
                    <div className="columns event-info-row is-mobile is-align-items-center">
                      <div className="column pl-0-mobile is-6-desktop is-half-mobile">
                        <p>
                          {intl.formatMessage({
                            id: 'dress_code',
                          })}
                          :
                        </p>
                      </div>
                      <div className="column pl-0-mobile flex-end-mobile is-6-desktop is-half-mobile">
                        <span>
                          {intl.formatMessage({
                            id: `dress_code_${event.dress_code}`,
                          })}
                        </span>
                      </div>
                    </div>
                    <div className="columns event-info-row is-mobile is-align-items-center">
                      <div className="column pl-0-mobile is-6-desktop is-half-mobile">
                        <p>
                          {intl.formatMessage({
                            id: 'seat',
                          })}
                          :
                        </p>
                      </div>
                      <div className="column pl-0-mobile flex-end-mobile is-6-desktop is-half-mobile">
                        <span>{venue.capacity}</span>
                      </div>
                    </div>
                    <div className="columns event-info-row is-mobile is-align-items-center">
                      <div className="column pl-0-mobile is-6-desktop is-half-mobile">
                        <p>
                          {intl.formatMessage({
                            id: 'air_condition',
                          })}
                          :
                        </p>
                      </div>
                      <div className="column pl-0-mobile flex-end-mobile is-6-desktop is-half-mobile">
                        <span>
                          {venue.air_condition === 1
                            ? intl.formatMessage({
                              id: 'yes',
                            })
                            : intl.formatMessage({
                              id: 'no',
                            })}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="event-detail-wrapper event-description-wrapper is-hidden-mobile">
                <h3 className="bold">
                  {intl.formatMessage({
                    id: 'event_program',
                  })}
                </h3>
                <div className="columns">
                  <div className="column is-10">
                    <div className="text" dangerouslySetInnerHTML={{ __html: isDeLang() ? event.program_de : event.program }} />
                  </div>
                </div>
              </div>
              <div className="event-description-wrapper is-hidden-mobile">
                <h3 className="bold">
                  {intl.formatMessage({
                    id: 'event_description',
                  })}
                </h3>
                <div className="columns">
                  <div className="column is-10">
                    <div className="text" dangerouslySetInnerHTML={{ __html: isDeLang() ? event.description_de : event.description }} />
                  </div>
                </div>
              </div>
            </div>
            <div className='column'>
              <form onSubmit={validateAndRedirectToPayment} ref={ticketInfoRef}>
                <div className="ticket-details-wrapper">
                  <h3 className="bold">
                    {intl.formatMessage({ id: 'available_date' })}
                  </h3>
                  <div className="field">
                    <div className="control has-icons-right">
                      <DatePicker
                        value={prefilledDate}
                        className="input"
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date()}
                        includeDates={availableDates}
                        placeholderText={intl.formatMessage({
                          id: 'select_date',
                        })}
                        selected={date}
                        onChange={(date) => onChangeDate(date)}
                        required
                      />
                      <span className="icon is-right">
                        <img src={CalendarIcon} alt="Calendar icon" />
                      </span>
                    </div>
                  </div>

                  <div className="ticket-pricing-details">
                    {_size(event?.ticket_categories) > 0 ? (
                      <>
                        <h3 className="bold">
                          {intl.formatMessage({ id: 'ticket_categories_price' })}
                        </h3>
                        {event?.ticket_categories.map((eventTicket) => (
                          <>

                            <div key={eventTicket.id} className="columns event-info-row is-mobile is-hidden-desktop">
                              <div className="column is-half-mobile is-flex is-align-items-center	is-justify-content-space-between	">
                                {date && isOnDateRangeAndReturnThePrice(date, eventTicket.ticket_category_custom_price).status ? (
                                  <>
                                    <span className='mr-1'><em>{eventTicket.name}:{'  '}</em></span>
                                    <span className='has-text-right'>{isOnDateRangeAndReturnThePrice(date, eventTicket.ticket_category_custom_price).price} €</span>
                                  </>
                                ) : (
                                  <>
                                    <span className='mr-1'><em>{eventTicket.name}:{'  '}</em></span>
                                    <span className='has-text-right'>{eventTicket.price} €</span>
                                  </>

                                )}
                              </div>

                              <div className="column is-half-mobile number-tickets">
                                <button
                                  type='button'
                                  className="ticket-amount-control decrease-nr"
                                  disabled={
                                    _get(selectedNrOfTickets, eventTicket.id, 0) ===
                                    0
                                  }
                                  onClick={(e) =>
                                    onChangeSelectedNumberOfTicket(
                                      e,
                                      eventTicket.id,
                                      'decrease'
                                    )
                                  }
                                >
                                  -
                                </button>
                                <input
                                  className="ticket-amount"
                                  type="text"
                                  value={_get(
                                    selectedNrOfTickets,
                                    eventTicket.id,
                                    0
                                  )}
                                />
                                <button
                                  type='button'
                                  className="ticket-amount-control increase-nr"
                                  onClick={(e) =>
                                    onChangeSelectedNumberOfTicket(
                                      e,
                                      eventTicket.id,
                                      'increase'
                                    )
                                  }
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            <div
                              key={eventTicket.id}
                              className="columns event-info-row is-mobile is-hidden-mobile"
                            >
                              <div className="column is-4-desktop">
                                <p>{eventTicket.name}:</p>
                              </div>
                              {date && isOnDateRangeAndReturnThePrice(date, eventTicket.ticket_category_custom_price).status ? (
                                <div className="column is-one-third-mobile">
                                  <span>{isOnDateRangeAndReturnThePrice(date, eventTicket.ticket_category_custom_price).price} €</span>
                                </div>
                              ) : (
                                <div className="column is-one-third-mobile">
                                  <span>{eventTicket.price} €</span>
                                </div>
                              )}
                              <div className="column is-3-desktop number-tickets">
                                <button
                                  type='button'
                                  className="ticket-amount-control decrease-nr"
                                  disabled={
                                    _get(selectedNrOfTickets, eventTicket.id, 0) ===
                                    0
                                  }
                                  onClick={(e) =>
                                    onChangeSelectedNumberOfTicket(
                                      e,
                                      eventTicket.id,
                                      'decrease'
                                    )
                                  }
                                >
                                  -
                                </button>
                                <input
                                  className="ticket-amount"
                                  type="text"
                                  value={_get(
                                    selectedNrOfTickets,
                                    eventTicket.id,
                                    0
                                  )}
                                />
                                <button
                                  type='button'
                                  className="ticket-amount-control increase-nr"
                                  onClick={(e) =>
                                    onChangeSelectedNumberOfTicket(
                                      e,
                                      eventTicket.id,
                                      'increase'
                                    )
                                  }
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </>
                        ))}
                      </>
                    ) : (
                      <>
                        <div className="columns event-info-row is-mobile">
                          <div className="column">
                            <p>
                              {intl.formatMessage({ id: 'no_tickets_available' })}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <button
                    ref={bookTicketInfoRef}
                    className="button book-now-btn"
                    disabled={_size(event.ticket_categories) === 0}
                    type="submit"
                  >
                    {intl.formatMessage({ id: 'book_ticket' })}
                  </button>
                </div>
              </form>
              <div className='ticket-details-wrapper'>
                {venue.schema !== null && (
                  <div className="ticket-pricing-details">
                    <h3 className="bold">
                      {intl.formatMessage({
                        id: 'seating',
                      })}
                    </h3>
                    <AdvancedImage
                      className="image-thumb"
                      cldImg={optimizeImageUrl(venue.schema, { quality: "auto" })}
                      plugins={[responsive(), lazyload()]}
                    >
                    </AdvancedImage>
                  </div>
                )}
                {venue.map_location !== undefined && (
                  <div className="ticket-pricing-details">
                    <h3 className="bold">
                      {intl.formatMessage({
                        id: 'location',
                      })}
                    </h3>
                    {/* <div className="event-map-wrapper">
                      <OpenStreetMap address={venue.location} map_location={venue.map_location} />
                    </div> */}
                    <div className="event-map-wrapper">
                      <GoogleMap address={venue.location} map_location={venue.map_location} />
                    </div>
                  </div>
                )}
                <Reviews reviews={reviews} />

                <div
                  className="event-description-wrapper is-hidden-desktop"
                  style={{ marginTop: '30px' }}
                >
                  <h3 className="bold">
                    {intl.formatMessage({
                      id: 'event_program',
                    })}
                  </h3>
                  <div className="columns">
                    <div className="column description is-10">
                      <ReadMore>
                        {isDeLang() ? event.program_de : event.program}
                      </ReadMore>
                    </div>
                  </div>
                </div>
                <div
                  className="event-description-wrapper is-hidden-desktop"
                  style={{ marginTop: '30px' }}
                >
                  <h3 className="bold">
                    {intl.formatMessage({
                      id: 'event_description',
                    })}
                  </h3>
                  <div className="columns">
                    <div className="column description is-10">
                      <ReadMore>
                        {isDeLang() ? event.description_de : event.description}
                      </ReadMore>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {_size(images) > 0 ? (
            <>
              <div className="columns">
                <div className="column">
                  <h3 className="bold">
                    {intl.formatMessage({
                      id: 'photos_impression',
                    })}
                  </h3>
                </div>
              </div>
              <div className="columns is-multiline">
                <Slider images={images} />
              </div>
            </>
          ) : null}
        </div>
      </section >
    </Layout >
  );
}
export const Head = ({ location, pageContext }) => {
  return (
    <Seo
      title={pageContext.language === 'de' ? pageContext.event.name_de : pageContext.event.name}
      description={removeTags(pageContext.language === 'de' ? pageContext.event.description_de : pageContext.event.description)}
      image={optimizeImageUrl(pageContext.event.main_image, { returnUrl: true })}
      pathname={location.pathname}
      keywords={pageContext.event.keywords}
    />
  );
};